.page-links {
	display: flex;
	gap: 2rem;
}

a {
	color: rgb(209, 213, 219);
	font-size: 14px;
	font-weight: 700;

	&:hover {
		color: white;
	}
}

.mobile-menu-logo {
	width: 150px !important;
}

.animation {
	height: 100%;
}

.logo-img {
	position: relative;

	a {
		display: block;
		height: 100%;
	}

	.main-logo {
		position: absolute;
		height: 16px;
    top: 25px;
    left: 4px;
		height: 17px;
	}
}
@media only screen and (max-width: 968px) {

	.page-links,
	.contact-btn {
		display: none;
	}

	.menu-outlined {
		display: block;
	}
}

@media (min-width: 968px) {

	.page-links,
	.contact-btn {
		display: flex;
	}

	.menu-outlined {
		display: none;
	}
}