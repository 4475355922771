html {
	scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-layout {
  background-color: rgb(17, 24, 39);
  color: white;
}

.ant-layout-header {
  background-color: rgb(17, 24, 39);
  color: white;
}

.ant-layout-footer {
  background-color: rgb(17, 24, 39);
  color: white;
  border-top: 1px solid rgb(31, 41, 55);

  color: rgb(107, 114, 128);
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
}

.ant-layout-footer svg {
  width: 30px;
  height: 30px;
}

.ant-input {
  background-color: rgba(255, 255, 255, 0.04);
}

.logo-img {
  height: 100%;
  cursor: pointer;
}

.custom-btn {
  background-color: rgb(147, 51, 234);
  width: fit-content;
  padding: 10px 20px;
  border-radius: 25px;
  border: 0;
  color: white;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: rgb(168, 85, 247);
  }
}

.ant-drawer-title {
  width: 200px;
}

.vi__wrapper {
  margin: 50px auto;
  
  .container {
    width: 400px;
    gap: 20px;
  }
}

.page-title {
  font-size: 1.5rem;
}

h2 {
  font-weight: normal;
}

h4 {
  margin-top: 0;
  margin-bottom: 10px;
}

.output-wrapper {
  position: relative;
}

.copy-btn-sec {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.copy-btn {
  color: white;
  background: #3584fc;
  padding: 5px 8px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    color: white !important;

    &:disabled {
      color: rgba(255, 255, 255, 0.25) !important;
    }
  }
}


@media screen and (max-width: 968px) {
  .page-content {
    flex-direction: column;
  }

  .site-layout-content {
    padding: 0 20px 20px 20px;
  }

  .vi__wrapper {
    .container {
      width: 300px;
      gap: 8px;
    }
  }
}